<template>
  <div id="app">
    <Nav/>
    <router-view/>
  </div>
</template>

<script>
export default {
  data(){
    return{

    }
  },
  mounted(){
    // if(document.body.clientWidth > 1200){
    //   window.location.href="http://www.timespark.cn/"
    // }
  }
}
</script>

<style lang="scss">
#app{
  width: 100%;
  height: 100%;
  font-size: 20px;
}
</style>
