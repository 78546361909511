<template>
    <div class="nav">
        <div class="logo" @click="gotopage('/home')">
            <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/timespark.png" alt="">
        </div>
        <div class="icon" @click="changeicon">
            <van-icon :name="flag ? 'descending':'ascending'" />
        </div>
        <van-collapse v-model="activeNames" accordion class="collapse" v-show="flag">
            <van-collapse-item title="产品与服务" name="1">
                <div class="product">
                    <p @click="gotopage('/wiscity')">智慧城市</p>
                    <p @click="gotopage('/wispub')">智慧公安</p>
                    <p @click="gotopage('/wistra')">智慧交通</p>
                    <p @click="gotoopen('http://sms.zhxsms.cn')">短信服务网关</p>
                    <p @click="gotoopen('https://www.flyreport.cn')">飞报大师</p>
                </div>
            </van-collapse-item>

            <van-collapse-item title="解决方案" name="2">
                <h4>行业解决方案</h4>
                <div class="product mb30">
                    <p @click="gotopage('/energy')">能源</p>
                    <p @click="gotopage('/community')">社区</p>
                    <p @click="gotopage('/manufacturing')">制造业</p>
                    <p @click="gotopage('/enterprise')">企业服务</p>
                    <p @click="gotopage('/fire')">消防</p>
                    <p @click="gotopage('/electricity')">电子商务</p>
                </div>
                <h4>技术解决方案</h4>
                <div class="product">
                    <p @click="gotopage('/blockchain')">区块链</p>
                    <p @click="gotopage('/bigdata')">大数据</p>
                    <p @click="gotopage('/Internet')">物联网</p>
                    <p @click="gotopage('/ai')">人工智能</p>
                </div>
            </van-collapse-item>

            <van-collapse-item title="应用案例" name="3">
                <p @click="gotopage('/case')">应用案例</p>
            </van-collapse-item>

            <van-collapse-item title="关于我们" name="4">
                <div class="product">
                    <p @click="gotopage('/introduce')">公司介绍</p>
                    <p @click="gotopage('/joinus')">加入我们</p>
                </div>
            </van-collapse-item>
        </van-collapse>
    </div>
</template>

<script>
export default {
    data(){
        return{
            activeNames:'0',
            flag:false
        }
    },
    methods:{
        changeicon(){
            this.flag = !this.flag
        },
        gotopage(pay){
            this.$router.push(pay)
            this.flag = false
        },
        gotoopen(pay){
            window.open(pay)
        }
    }
}
</script>

<style lang="scss" scoped>
.nav{
    width: 100%;
    height: 100px;
    padding: 0 30px 0 20px;
    background-color: #fff;
    position: -webkit-sticky;
    position: sticky !important;/*兼容*/
    top: 0;
    left: 0;
    z-index: 99999;
    display: flex;
    justify-content: space-between;
    .logo{
        display: flex;
        align-items: center;
        img{
            height: 60px;
        }
    }
    .collapse{
        width: 100%;
        position: absolute;
        top: 80px;
        left: 0;
        h4{
            color: black;
        }
        .product{
            display: flex;
            flex-wrap: wrap;
            p{
                width: 33%;
                height: 60px;
                line-height: 60px;
                font-size: 24px;
            }
        }
        .mb30{
            margin-bottom: 30px;
        }
    }
    .icon{
        font-size: 58px;
        line-height: 100px;
    }
}
</style>