import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/home',  //首页
    name: 'home',
    component: () => import('@/views/home')
  },
  {
    path: '/introduce', 
    name: 'introduce',
    component: () => import('@/views/introduce')
  },
  {
    path: '/joinUS',
    name: 'joinUS',
    component: () => import('@/views/joinUS')
  },
  {
    path: '/wiscity',
    name: 'wiscity',
    component: () => import('@/views/wisdom/wiscity.vue')
  },
  {
    path: '/wispub',
    name: 'wispub',
    component: () => import('@/views/wisdom/wispub.vue')
  },

  // 行业解决方案
  {
    path: '/wistra',
    name: 'wistra',
    component: () => import('@/views/wisdom/wistra.vue')
  },
  {
    path: '/energy',
    name: 'energy',
    component: () => import('@/views/industry/energy.vue')
  },
  {
    path: '/community',
    name: 'community',
    component: () => import('@/views/industry/community.vue')
  },
  {
    path: '/manufacturing',
    name: 'manufacturing',
    component: () => import('@/views/industry/manufacturing.vue')
  },
  {
    path: '/enterprise',
    name: 'enterprise',
    component: () => import('@/views/industry/enterprise.vue')
  },
  {
    path: '/fire',
    name: 'fire',
    component: () => import('@/views/industry/fire.vue')
  },
  {
    path: '/electricity',
    name: 'electricity',
    component: () => import('@/views/industry/electricity.vue')
  },

  // 应用案例
  {
    path: '/case',
    name: 'case',
    component: () => import('@/views/case/index.vue')
  },
  {
    path: '/base',
    name: 'base',
    component: () => import('@/views/case/base.vue')
  },
  {
    path: '/data',
    name: 'data',
    component: () => import('@/views/case/data.vue')
  },
  {
    path: '/work',
    name: 'work',
    component: () => import('@/views/case/work.vue')
  },
  {
    path: '/chain',
    name: 'chain',
    component: () => import('@/views/case/chain.vue')
  },
  {
    path: '/sms',
    name: 'sms',
    component: () => import('@/views/case/sms.vue')
  },
  {
    path: '/peace',
    name: 'peace',
    component: () => import('@/views/case/peace.vue')
  },
  {
    path: '/integration',
    name: 'integration',
    component: () => import('@/views/case/integration.vue')
  },
  {
    path: '/synergy',
    name: 'synergy',
    component: () => import('@/views/case/synergy.vue')
  },
  
  // 技术解决方案
  {
    path: '/blockchain',
    name: 'blockchain',
    component: () => import('@/views/technology/blockchain.vue')
  },
  {
    path: '/bigdata',
    name: 'bigdata',
    component: () => import('@/views/technology/bigdata.vue')
  },
  {
    path: '/Internet',
    name: 'Internet',
    component: () => import('@/views/technology/Internet.vue')
  },
  {
    path: '/ai',
    name: 'ai',
    component: () => import('@/views/technology/ai.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
})

export default router
