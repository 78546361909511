import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'amfe-flexible'  //rem适配
import "@/style/index.scss"   //全局样式
import 'vant/lib/index.css';  //vant
import {Button, Field, RadioGroup, Radio, Swipe, SwipeItem, Icon, Tab, Tabs, Form, Collapse, CollapseItem } from 'vant';

Vue
.use(Button)
.use(Field)
.use(RadioGroup)
.use(Radio)
.use(Swipe)
.use(SwipeItem)
.use(Icon)
.use(Tab)
.use(Tabs)
.use(Form)
.use(Collapse)
.use(CollapseItem)

import Footer from './components/Footer.vue'
import Nav from './components/Nav.vue'
Vue.component('Footer',Footer);
Vue.component('Nav',Nav);




Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
