<template>
    <div class="footer">
        <div class="giveme">
            <p>立即联系泰铭恒创专家团队，为您提供解决方案</p>
            <van-button color="#FB5604" @click="gotopage">联系我们</van-button>
        </div>
        <ul class="list">
            <li>
                <h4>关于我们</h4>
                <p><a href="/introduce">公司介绍</a></p>
                <p><a href="/joinus">联系我们</a></p>
                <p><a href="case">客户案例</a></p>
            </li>
            <li>
                <h4>产品服务</h4>
                <p><a href="wiscity">智慧城市</a></p>
                <p><a href="https://www.flyreport.cn">飞报大师</a></p>
                <p><a href="wispub">智慧公安</a></p>
                <p><a href="wistra">智慧交通</a></p>
                <p><a href="http://sms.zhxsms.cn">短信网关</a></p>
            </li>
            <li>
                <h4>行业解决方案</h4>
                <p><a href="wiscity">政务场景</a></p>
                <p><a href="/energy">能源场景</a></p>
                <p><a href="/community">社区场景</a></p>
                <p><a href="/wispub">公安场景</a></p>
                <p><a href="/manufacturing">制造业场景</a></p>
                <p><a href="/electricity">电子商务场景</a></p>
                <p><a href="https://www.51eagle.com/home">教育场景</a></p>
                <p><a href="/fire">消防场景</a></p>
                <p><a href="/enterprise">企业服务场景</a></p>
            </li>
            <li>
                <h4>技术解决方案</h4>
                <p><a href="/blockchain">区块链</a></p>
                <p><a href="/bigdata">大数据</a></p>
                <p><a href="/Internet">物联网</a></p>
                <p><a href="/ai">人工智能</a></p>
            </li>
            <li>
                <h4>友情链接</h4>
                <p><a href="https://www.51eagle.com/home">千鹰云校</a></p>
            </li>
        </ul>
        <div class="Address">
            <div class="Contact">
                <h4>联系我们</h4>
                <p>联系电话：(027) 5976-5360</p>
                <p>企业邮箱：biz@timespark.cn</p>
                <p class="df">
                    <span class="bl">联系地址：</span>
                    <span class="bl w70">武汉市东湖新技术开发区华师园一路光元科技园</span></p>
            </div>
            <div class="MapImg">
                <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/地址2.png" alt="">
            </div>
            <div class="QrCode">
                <div class="Code_l">
                    <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/code.png" alt="">
                    <p>联系客服</p>
                </div>
                <div class="Code_l">
                    <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/code.png" alt="">
                    <p>关注公众号</p>
                </div>
            </div>
        </div>
        <div class="tmhc">
            <p>
                武汉泰铭恒创信息技术股份有限公司 ©Copyright2017-2020 All Rights Reserved 鄂ICP备17025230号
            </p>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            
        }
    },
    mounted(){
        console.log(this.$route);
    },
    methods:{
        gotopage(){
            this.$router.push('/joinus')
        }
    }
}
</script>

<style lang="scss" scoped>
.footer{
    width: 100%;
    background-color: #1e1e1e;
    .giveme{
        height: 101px;
        background: url('http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/联系我们.png') no-repeat;
        background-size:100% 100%;
        display: flex;
        justify-content: space-around;
        p{
            font-size: 21px;
            line-height: 101px;
            color: #fff;
        }
        .van-button{
            width: 181px;
            height: 52px;
            margin-top: 25px;
            font-size: 21px;
            border-radius: 10px;
        }
        
    }
    // list
    .list{
        display: flex;
        justify-content: space-around;
        padding-top: 32px;
        padding-bottom: 51px;
        li{
            // a{
            //     color: #ccc;
            // }
            h4{
                margin-bottom: 10px;
                font-size: 18px;
                color: #fff;
            }
            p{
                a{
                    font-size: 14px;
                    line-height: 28px;
                    color: #ccc;
                }
            }
        }
        
    }
    // Address
    .Address{
        display: flex;
        justify-content: space-around;
        padding-bottom: 41px;
        color: #fff;
        .Contact{
            width: 33%;
            h4{
                font-size: 18px;
                font-weight: 900;
                margin-bottom: 10px;
            }
            p{
                font-size: 14px;
                line-height: 28px;
                .bl{
                    display: block;
                }
            }
            .df{
                display: flex;
            }
            .w70{
                width: 68%;
            }
        }
        .MapImg{
            img{
                width: 205px;
                height: 125px;
            }
        }
        .QrCode{
            display: flex;
            .Code_l{
                margin-right: 10px;
                img{
                    width: 78px;
                    height: 78px;
                }
                p{
                    font-size: 14px;
                    padding-top: 25px;
                    text-align: center;
                }
            }
        }
    }
    .tmhc{
        width: 100%;
        height: 42px;
        background-color: #333;
        color: #ccc;
        font-size: 14px;
        text-align: center;
        line-height: 42px;
    }
}
</style>